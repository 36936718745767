import { ReactElement, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';

import { MoonStars, Sun } from '@phosphor-icons/react';
import clsx from 'clsx';
import { AnimatePresence, motion, MotionProps, Variants } from 'framer-motion';

import EquitesIconWhite from '@shared/assets/images/icon-white.webp';
import EquitesIcon from '@shared/assets/images/icon.webp';
import EquitesLogoWhite from '@shared/assets/images/logo-white.webp';
import EquitesLogo from '@shared/assets/images/logo.webp';
import { Chevron } from '@shared/components/Chevron';
import { Switch } from '@shared/components/Switch';
import { MenuItems } from '@shared/routes/MenuItems';

import { useSidebar } from '@modules/globals/hooks/useSidebar';
import { useTheme } from '@modules/globals/hooks/useTheme';

import { SidebarItem } from './SidebarItem';
import { SidebarSubItem } from './SidebarSubItem';

export const Sidebar = (): ReactElement => {
  const { pathname } = useLocation();
  const { sidebarOpened, menuOpened, handleMenuOpened, handleMenuSelected, toggleSidebarOpened } = useSidebar();
  const { handleChangeTheme, theme } = useTheme();

  const Logo = theme === 'dark' ? EquitesLogoWhite : EquitesLogo;
  const Icon = theme === 'dark' ? EquitesIconWhite : EquitesIcon;

  const variantsItems: Variants = {
    hidden: { y: -5, opacity: 0 },
    visible: (i: number) => ({ y: 0, opacity: 1, transition: { delay: i * 0.1, type: 'tween' } }),
    exit: (i: number) => ({ y: -5, opacity: 0, transition: { delay: i * 0.1, type: 'tween' } }),
  };

  const variantsSubItems: Variants = {
    hidden: { y: -5, opacity: 0 },
    visible: (i: number) => ({ y: 0, opacity: 1, transition: { delay: i * 0.01, duration: 0.2, type: 'tween' } }),
    exit: (i: number) => ({ y: -5, opacity: 0, transition: { delay: i * 0.01, duration: 0.2, type: 'tween' } }),
  };

  const getAnimationItemsProps = (index: number, variants: Variants): MotionProps => {
    return { initial: 'hidden', animate: 'visible', exit: 'exit', custom: index, variants };
  };

  useEffect(() => {
    const currentMainPath = MenuItems.find(item => item.path === pathname);

    if (currentMainPath) {
      handleMenuSelected(currentMainPath.id);
    }

    const currentChildPath = MenuItems.map(i => i.items)
      .flatMap(item => item)
      .find(item => item?.path === pathname);

    if (currentChildPath) {
      handleMenuSelected(currentChildPath.id);
    }
  }, [handleMenuOpened, handleMenuSelected, pathname]);

  return (
    <aside
      className={clsx(
        'fixed hidden h-full pb-4 pt-8 shadow-lg transition-all duration-300 dark:bg-black-500 dark:shadow-black-400 lg:flex',
        {
          'w-[235px]': sidebarOpened && isDesktop,
          'w-[117px]': !sidebarOpened && isDesktop,
        },
      )}
    >
      <div className="relative flex h-full w-full flex-col items-center">
        {/* Toggle open and close */}
        <button
          aria-label="chevron"
          type="button"
          onClick={toggleSidebarOpened}
          className="absolute -right-3 top-0 flex h-6 w-6 items-center justify-center rounded-full bg-white shadow-lg dark:bg-black-400"
        >
          <Chevron
            height={11}
            className={clsx('fill-gray-500 transition-transform duration-300', { 'rotate-180': sidebarOpened })}
          />
        </button>

        {/* Equites logo or icon */}
        <Link to="/" className="mb-8 flex h-16 items-center justify-center">
          <img src={sidebarOpened ? Logo : Icon} alt="Equites" className="h-full w-full transition-all duration-200" />
        </Link>

        {/* Items */}
        <div className="flex w-full flex-1 flex-col">
          {MenuItems.map((item, index) => {
            const { id, items } = item;

            if (items) {
              return (
                <motion.div key={id} {...getAnimationItemsProps(index, variantsItems)} className="flex flex-col">
                  <SidebarItem {...item} />

                  {/* Sub items */}
                  <AnimatePresence mode="wait">
                    {menuOpened === id && (
                      <div className="ml-6 flex flex-col border-l-[1px] border-l-black-400/10 pl-2 dark:border-l-black-400">
                        {items.map((subItem, subItemIndex) => (
                          <SidebarSubItem
                            key={subItem.id}
                            {...getAnimationItemsProps(subItemIndex, variantsSubItems)}
                            {...subItem}
                          />
                        ))}
                      </div>
                    )}
                  </AnimatePresence>
                </motion.div>
              );
            }

            return <SidebarItem key={id} {...getAnimationItemsProps(index, variantsItems)} {...item} />;
          })}
        </div>

        {/* Toggle theme */}
        <AnimatePresence>
          {sidebarOpened && (
            <motion.div
              className="flex items-center gap-1"
              initial={{ opacity: 0, x: -5 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ type: 'tween', delay: 0.3 }}
            >
              <Sun size={16} className="text-gray-500 dark:text-gray-400" />
              <Switch checked={theme === 'dark'} onCheckedChange={() => handleChangeTheme()} />
              <MoonStars size={16} className="text-gray-500 dark:text-gray-400" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </aside>
  );
};
