import { ButtonHTMLAttributes, memo, ReactElement, ReactNode } from 'react';

import { Slot, SlotProps } from '@radix-ui/react-slot';
import { ForwardRefComponent, HTMLMotionProps, motion, MotionProps } from 'framer-motion';
import { twJoin } from 'tailwind-merge';

type ICustomPropsWithAnimation = ButtonHTMLAttributes<HTMLButtonElement> & MotionProps;

export interface ITextProps extends ICustomPropsWithAnimation {
  children: ReactNode;
  asChild?: boolean;
}

const TextComponent = ({ children, asChild, className, ...props }: ITextProps): ReactElement => {
  const Component:
    | React.ForwardRefExoticComponent<SlotProps & React.RefAttributes<HTMLElement>>
    | ForwardRefComponent<HTMLSpanElement, HTMLMotionProps<'span'>> = asChild ? Slot : motion.span;

  return (
    <Component className={twJoin(`font-sans leading-none antialiased`, className)} {...props}>
      {children}
    </Component>
  );
};

export const Text = memo(TextComponent);
