import { memo } from 'react';

import { Text } from '@shared/components/Text';

interface IPageTitleProps {
  title: string;
  subtitle?: string;
}

const PageTitleComponent: React.FC<IPageTitleProps> = ({ title, subtitle }) => {
  return (
    <div className="mb-4 flex flex-col gap-1 lg:mb-10">
      <Text className="text-center text-xl font-bold text-black-400 dark:text-white lg:text-left">{title}</Text>
      {subtitle && <Text className="text-center text-md text-gray-500 lg:text-left">{subtitle}</Text>}
    </div>
  );
};

export const PageTitle = memo(PageTitleComponent);
