import { ReactElement, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@phosphor-icons/react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';

import { IAvatarContentProps, IAvatarProps, avatarVariants } from '@shared/components/Avatar/types/AvatarTypes';

// ------------------------------------------

const AvatarContent = (props: IAvatarContentProps): ReactElement => {
  const { classNameImg, icon: Icon = User, iconSize, mode, size, url } = props;

  const { t } = useTranslation('globals');

  const { image } = avatarVariants({ mode, size });

  const getIconSize = (): number => {
    switch (size) {
      case '3xs':
        return iconSize ?? 12;
      case 'xxs':
        return iconSize ?? 16;
      case 'xs':
        return iconSize ?? 24;
      case 'sm':
        return iconSize ?? 34;
      case 'md':
        return iconSize ?? 54;
      case 'lg':
        return iconSize ?? 74;
      case 'xl':
        return iconSize ?? 94;
      case 'full':
        return iconSize ?? 94;
      default:
        return 74;
    }
  };

  return (
    <>
      <AvatarPrimitive.Image src={url ?? ''} alt={t('profile_image')} className={image({ className: classNameImg })} />

      <AvatarPrimitive.Fallback delayMs={600}>
        <Icon size={getIconSize()} className="text-gray-500" />
      </AvatarPrimitive.Fallback>
    </>
  );
};

AvatarContent.displayName = 'Avatar.Content';

// ------------------------------------------

const AvatarRoot = (props: IAvatarProps): ReactElement => {
  const { children, mode = 'SQUARE', size, classNameContainer, classNameRoot, onClick } = props;

  const { container } = avatarVariants({ mode, size });

  return (
    <AvatarPrimitive.Root className={classNameRoot}>
      {onClick ? (
        <button type="button" onClick={onClick} className={container({ className: classNameContainer })}>
          {cloneElement(children, { mode, size })}
        </button>
      ) : (
        <div className={container({ className: classNameContainer })}>{cloneElement(children, { mode, size })}</div>
      )}
    </AvatarPrimitive.Root>
  );
};

AvatarRoot.displayName = 'Avatar.Root';

// ------------------------------------------

export const Avatar = {
  Root: AvatarRoot,
  Content: AvatarContent,
};
