import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowLeft } from '@phosphor-icons/react';

import { Text } from '@shared/components/Text';

interface IGoBackPageProps {
  /**
   * Text to show, is required
   */
  text: string;
  /**
   * Default is navigate to before page, with navigate(-1)
   * @returns void
   */
  goBack?: () => void;
}

export const GoBackPage = ({ text, goBack }: IGoBackPageProps): ReactElement => {
  const navigate = useNavigate();

  function navigateBack(): void {
    if (goBack) {
      goBack();
    } else {
      navigate(-1);
    }
  }

  return (
    <button type="button" className="mb-8 flex w-fit items-center gap-3 lg:mb-14" onClick={navigateBack}>
      <ArrowLeft size={24} className="text-green-500" />
      <Text className="text-lg text-black-400 dark:text-white">{text}</Text>
    </button>
  );
};
