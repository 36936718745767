import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { SpinnerGap } from '@phosphor-icons/react';
import { Slot } from '@radix-ui/react-slot';

import { IButtonProps, buttonVariants, spinnerVariants } from '@shared/components/Button/types/ButtonTypes';
import { Text } from '@shared/components/Text';

export const Button = ({
  children,
  asChild,
  className,
  variant,
  color,
  type = 'button',
  isLoading = false,
  disabled = false,
  textOnLoading,
  ...props
}: IButtonProps): ReactElement => {
  const { t } = useTranslation('globals');

  const Component = asChild ? Slot : 'button';

  return (
    <Component
      className={buttonVariants({ color, variant, className })}
      disabled={disabled || isLoading}
      type={type}
      {...props}
    >
      {isLoading ? (
        <div className="flex justify-center gap-2">
          <SpinnerGap className={spinnerVariants({ color, variant })} weight="bold" />
          <Text className="text-md">{textOnLoading ?? t('loading')}</Text>
        </div>
      ) : (
        children
      )}
    </Component>
  );
};
