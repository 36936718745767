import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createContext } from 'use-context-selector';

import { Storage } from '@shared/constants/Storage';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';
import { sleep } from '@shared/utils/SleepFunction';
import { usePersistedState } from '@shared/utils/usePersistedState';

import { ITokens } from '@modules/auth/types/Auth/auth';
import { IAuthContext } from '@modules/auth/types/Auth/context';
import { ISignInByTokensRequest } from '@modules/auth/types/Auth/requests';

import { useLoader } from '@modules/globals/hooks/useLoader';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);
AuthContext.displayName = 'Auth';

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const { startLoad, endLoad } = useLoader();

  const getTokensStored = (): ITokens | null => {
    const tokensStored = localStorage.getItem(Storage.TOKENS);
    return tokensStored ? JSON.parse(tokensStored) : null;
  };

  const [isAuthenticated, setIsAuthenticated] = useState(() => !!getTokensStored());
  const [tokens, setTokens] = usePersistedState<ITokens | null>(Storage.TOKENS, getTokensStored());

  const signInByTokens = useCallback(
    async (data: ISignInByTokensRequest) => {
      try {
        startLoad();

        const { accessToken, equitesToken, refreshToken } = data;

        setIsAuthenticated(true);
        setTokens({ accessToken, equitesToken, refreshToken });

        await sleep(150);

        navigate('/', { replace: true });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, navigate, setTokens, startLoad],
  );

  const signOut = useCallback(async () => {
    setIsAuthenticated(false);
    setTokens(null);

    window.location.href = import.meta.env.VITE_DOMINATOR_APP_URL;
  }, [setTokens]);

  const contextValue = useMemo<IAuthContext>(
    () => ({ isAuthenticated, tokens, signInByTokens, signOut }),
    [isAuthenticated, signInByTokens, signOut, tokens],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
