import { ReactElement } from 'react';

import * as SwitchPrimitive from '@radix-ui/react-switch';

import { ISwitchProps, switchVariants } from '@shared/components/Switch/types/SwitchTypes';

export const Switch = ({ checked, disabled, onCheckedChange, ...props }: ISwitchProps): ReactElement => {
  const { root, thumb } = switchVariants({ checked });

  return (
    <SwitchPrimitive.Root
      checked={checked}
      onCheckedChange={onCheckedChange}
      disabled={disabled}
      aria-disabled={disabled}
      className={root()}
      {...props}
    >
      <SwitchPrimitive.Thumb aria-disabled={disabled} className={thumb()} />
    </SwitchPrimitive.Root>
  );
};
